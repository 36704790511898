import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
// import AboutPageSection1 from '../screens/ComponentsPages/AboutPageSection1'
// import AboutPageSection2 from '../screens/ComponentsPages/AboutPageSection2'
// import HomepageSectionFive from '../screens/ComponentsPages/HomepageSection5'
// import AboutCallPage from '../screens/ComponentsPages/AboutCallPage'

const AboutPageSection1 = React.lazy(()=> import('../screens/ComponentsPages/AboutPageSection1'));
const AboutPageSection2 = React.lazy(()=> import('../screens/ComponentsPages/AboutPageSection2'));
const HomepageSectionFive = React.lazy(()=> import('../screens/ComponentsPages/HomepageSection5'));
const AboutCallPage = React.lazy(()=> import('../screens/ComponentsPages/AboutCallPage'));

 

const About  = () => {
    return (
        <div className='relative'> 

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Founded in 2020, “Marudhar Machines” has rapidly emerged 
                               as a prominent player in the Automatic PVC Pipe Bending Machine Manufacturer 
                               sector, specializing in the Production of advanced machines that are tailored 
                               for pipe-related applications. As a unique and known Manufacturer, Retailer 
                               and Supplier, we have fiend a remarkable reputation within a short span of 
                               time and too organically just on the basis of the customer reviews we have 
                               seen a massive surge in our sales.'
                               
                />
                <meta name='keywords' 
                      content='Semi Automatic pvc Pipe Bending Machine, Automatic pvc pipe Bending Machine, PVC Pipe Bending Machine, PVC Pipe Cutter Machine, PVC Pipe Coupler Machine, PVC Pipe Soketing Machine, Bending Machine, Cutter Machine, Electric Pipe Bending Machine, Bend Making Machine '        
                />
                <title>About</title>
                <link rel="canonical" href="https://marudharmachines.com/about" />
            </Helmet>


            <Suspense fallback={<div>Loading...</div>}>
                <div className='bg-purpleHeader'>
                   
                <AboutPageSection1/>
                <AboutPageSection2/>
                <HomepageSectionFive/> 
                <AboutCallPage/>
                 
                </div>
            </Suspense>
        </div>

        );
    }
export default About