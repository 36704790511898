import React from 'react'

import {Link} from "react-router-dom";

 

const ChatButton =() =>  {

    return (

      <>
        
                          <div className=' md:visible invisible  fixed bottom-8 left-8 z-100'>
                            <Link to="https://api.whatsapp.com/send/?phone=918090409020&type=phone_number&app_absent=0">
                              <button class="  rounded-2xl    bg-green-400 p-[12px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] rounded-full hover:bg-green-600 hover:drop-shadow-lg active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300         
                                  hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                                    <img className=" w-[35px] h-[35px] " src="images/whatsapp.svg" alt=""/>
                                      <span className="text-[#ffff] tracking-[0.05em] font-semibold text-[20px]"> 
                                      </span>
                              </button>
                            </Link>
                          </div>

                          
                          <div className=' visible md:invisible -rotate-90	  fixed top-1/2 -right-[14%] z-100'>
                              <Link to="https://api.whatsapp.com/send/?phone=918090409020&type=phone_number&app_absent=0">
                                <button class="   flex gap-2      bg-green-400 py-1 px-2 shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]  rounded-t-2xl hover:bg-green-600 hover:drop-shadow-lg active:bg-green-700 focus:outline-none focus:ring focus:ring-green-300         
                                  hover:scale-105 duration-200     shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                                    <img className=" w-[25px] h-[25px] " src="images/whatsapp.svg" alt=""/>
                                        <span className="text-[#ffff] tracking-[0.05em] font-semibold text-[18px]"> 
                                        Chat Now
                                        </span>
                                </button>
                              </Link>
                          </div>

      </>
  )
}

 

export default ChatButton
    