import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
// import AboutPageSection1 from '../screens/ComponentsPages/AboutPageSection1'
// import ContactUsContactPage from '../screens/ComponentsPages/ContactUsContactPage'
// import AboutCallPage from '../screens/ComponentsPages/AboutCallPage'


const AboutPageSection1 = React.lazy(()=> import('../screens/ComponentsPages/AboutPageSection1'));
const ContactUsContactPage = React.lazy(()=> import('../screens/ComponentsPages/ContactUsContactPage'));
const AboutCallPage = React.lazy(()=> import('../screens/ComponentsPages/AboutCallPage'));

  
const ContactUs  = () => {
    return (
        <div className='relative'> 

            <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Our Phone Number : 8090409020, 7733001116 
                               Our Address : Near Motiyar Dharam Kanta, Opposite Karni Riico, Pugal Road, Bikaner-334004 (Rajasthan), India'
                               
                />
                <meta name='keywords' 
                      content='Semi Automatic pvc Pipe Bending Machine, Automatic pvc pipe Bending Machine, PVC Pipe Bending Machine, PVC Pipe Cutter Machine, PVC Pipe Coupler Machine, PVC Pipe Soketing Machine, Bending Machine, Cutter Machine, Electric Pipe Bending Machine, Bend Making Machine, Contact Us, Contact '        
                />
                <title>Contact Us</title>
                <link rel="canonical" href="https://marudharmachines.com/contactUs" />
            </Helmet>


        <Suspense fallback={<div>Loading...</div>}>
            <div className='bg-purpleHeader'>
               
                <AboutPageSection1/>
                <AboutCallPage/>
                <ContactUsContactPage/>
               
            </div>
         </Suspense>
        </div>

        );
    }
export default ContactUs