import React from "react";
import { Link } from 'react-router-dom'
import { RiWhatsappFill } from "react-icons/ri";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SiYoutubemusic } from "react-icons/si";

function Footer() {
  return (

<footer className="">

    <div className=" bg-[#0c0215] w-full h-[950px]  sm:h-[600px] md:h-[520px] lg:h-[450px] xl:h-[450px] 2xl:h-[400px] flex justify-center items-center md:justify-center md:items-center  " >
        
        <div className=" mx-5 sm:mx-6 md:mx-16 lg:mx-20 xl:mx-[85px] 2xl:mx-[100px] lg:my-6 rounded-3xl   shadow-[-6px_-6px_10px_rgb(82,103,124),6px_6px_10px_rgba(0,0,0,0.2)]">
      
        <div className="mx-4 py-5 md:py-4 lg:py-10 ">

        <div className="grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-3 gap-2 place-items-start    md:place-items-center  ">
        
        <div className="grid  col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-2  xl:col-span-1    ">

        
        <span className="  uppercase font-semibold mx-4 mb-4 flex items-center justify-center md:justify-start ">
          <Link to="/about">  
          <img src="/images/LOGO2.png" alt="footer logo" className=" w-[100px]" />
          </Link> 
        </span>
        

        <p className="text-[14px]  font-medium text-justify md:text-justify text-limedSpruce text-slate-400  md:mx-4">
            Welcome to Marudhar Machines! We are a leading manufacturer and supplier specializing in the production and distribution of a wide range of PVC pipe bending machines. <br/>
            Our product range includes PVC pipe bending machines ranging from 16mm to up to 50mm, PVC pipe coupler making machines in sizes from 16mm to up to 110mm, PVC pipe socketing machines,
            and PVC pipe cutter machines.
        </p>
      </div>


      <div className="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-1 mt-5 sm:mt-0">
          <h6 className="uppercase font-semibold mb-4 text-[#ffffff] text-[18px]  ">
            CUSTOMER SUPPORT
          </h6 >

          <Link to="https://api.whatsapp.com/send/?phone=918090409020&type=phone_number&app_absent=0">
          <p className="mb-4 flex items-start md:items-center    ">
            <RiWhatsappFill  className=" w-6 h-6 text-white   "/>
            <span className=" text-md  font-small text-justify   text-slate-400 mx-2">  +91 8090409020</span>
          </p>
          </Link>


          <Link to="tel:+91 8090409020 " >
          <p className="mb-4 flex items-start md:items-center  ">
            <FaPhoneVolume   className=" w-5 h-5  text-white"/>
            <span className=" text-md  font-small text-justify   text-slate-400 mx-2">  +91 7733001116</span>
          </p>
          </Link> 

          <Link to="mailto:marudharindustries5@gmail.com ">
          <p className="mb-4 flex items-start md:items-center   ">
            <IoMdMail   className=" w-6 h-6  text-white "/>
            <span className=" text-md  font-small text-justify   text-slate-400 mx-2"> marudharindustries5@gmail.com</span>
          </p>
          </Link> 


          <p className="mb-4 flex items-start md:items-center  ">
            <Link to="https://www.facebook.com/marudhar.industries" > <FaFacebook   className="text-white  w-8 h-8 hover:w-9 hover:h-9 transition duration-300 ease-in-out  mr-4 "/></Link>
            <Link to="https://www.instagram.com/marudhar.machines"><PiInstagramLogoFill   className="  text-white w-10 h-10 md:w-9 md:h-9 hover:w-11 hover:h-11 transition duration-300 ease-in-out    mr-4"/></Link>
            <Link to="https://twitter.com/Marudharmachine" ><AiFillTwitterCircle   className=" text-white w-10 h-10 md:w-9 md:h-9 hover:w-11 hover:h-11 transition duration-300 ease-in-out  rounded-full   mr-4 "/></Link>
            <Link to="https://www.youtube.com/@marudharindustries" > <SiYoutubemusic   className=" text-white w-9 h-9 md:w-8 md:h-8 hover:w-9 hover:h-9 transition duration-300 ease-in-out  rounded-full   mr-4 "/></Link>
           </p>

      </div>

      <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 mt-5 sm:mt-0">
          <h6 className="uppercase font-semibold mb-4 flex   md:justify-start text-[#ffffff] text-[18px] ">
           Useful Links
          </h6>

          <Link to="/" >
          <p className="mb-1 text-2xs  font-medium text-justify  text-limedSpruce text-slate-400">
              <span className=" ">Home</span>
          </p>
          </Link>

          <Link to="/about">
          <p className="mb-1 text-2xs  font-small text-justify  text-limedSpruce text-slate-400">
              <span className=" ">About us</span>
          </p>
          </Link>

          <Link to="/products">
          <p className="mb-1 text-2xs  font-small text-justify  text-limedSpruce text-slate-400">
              <span className=" ">Our Products</span>
          </p>
          </Link>

          <Link to="/gallery">
          <p className="mb-1 text-2xs  font-small text-justify  text-limedSpruce text-slate-400">
              <span className=" ">Gallery</span>
          </p>
          </Link>

          <Link to="/contactUs">
          <p className="mb-1 text-2xs  font-small text-justify  text-limedSpruce text-slate-400">
              <span className=" ">Contact us</span>
          </p>
          </Link>

          <p className="mb-1 text-2xs  font-small text-justify  text-limedSpruce text-slate-400">
              <span className=" ">SiteMap</span>
          </p>

          

      </div>

      {/* <div className=" col-span-2 ">
          <h6 className="uppercase font-semibold mb-4 flex   md:justify-start text-[#ffffff] text-[18px] ">
              PRODUCT
          </h6>

          <p className="mb-4 text-xs  font-small text-justify  text-limedSpruce text-slate-400">
              <a href="#!" className=" ">Courses</a>
          </p>

          <p className="mb-4 text-xs  font-small text-justify  text-limedSpruce text-slate-400">
              <a href="#!" className=" ">Pricing</a>
          </p>

          <p className="mb-4 text-xs  font-small text-justify  text-limedSpruce text-slate-400">
              <a href="#!" className=" ">Blog</a>
          </p>
      </div> */}


      {/* <div className="   ">
          <h6 className="uppercase font-semibold mb-4 flex    text-[#ffffff] text-[18px]">
              LEGAL
          </h6>

          <p className="mb-4 text-xs  font-small text-justify  text-limedSpruce text-slate-400">
              <a href="#!" className=" "> Terms & Conditions</a>
          </p>

          <p className="mb-4 text-xs  font-small text-justify  text-limedSpruce text-slate-400">
              <a href="#!" className=" "> Privacy Policy</a>
          </p>
      </div> */}

    </div>
    
  </div>
  </div>
  </div>
   
   <div className="pt-2 bg-[#000]  md:pb-3 pb-20 border-t border-1">
          <div className="mx-5 lg:mx-28 md:mx-40 lg:mx-28">
              <div className="   mt-2 flex flex-col md:flex-row md:justify-between items-center  ">
         
              
              <div className="col-span-1 md:col-span-2 lg:col-span-2    ">  
                <p className="font-semibold text-slate-400 font-small text-xs "> Copyright © 2024, All Rights Reserved </p>
              </div> 
                 
              <div className="col-span-1 md:col-span-2 lg:col-span-2   ">
                <Link className="font-semibold text-slate-400 font-small  text-xs  " to="/"> Privacy Policy  &nbsp;  | </Link>
                <Link className="font-semibold text-slate-400 font-small text-xs " to="/">&nbsp; Terms & Conditions</Link>
             </div>
            </div>
          </div> 
        </div>
</footer>

  );
}

export default Footer ;
