import React, { Suspense, lazy } from 'react'
import {Helmet} from "react-helmet";

// import Banner from '../components/Banner'
// import HomepageSectionOne from '../screens/ComponentsPages/HomepageSection1'
// import HomepageSectionTwo from '../screens/ComponentsPages/HomepageSection2'
// import HomepageSectionFive from '../screens/ComponentsPages/HomepageSection5'
// import ScrillingImages from '../screens/ComponentsPages/ScrillingImages'
// import HomePageGallery from '../screens/ComponentsPages/HomePageGallery'
// import HomeProductRangePage from '../screens/ComponentsPages/HomeProductRangePage'
// import HomeFormPage from '../screens/ComponentsPages/HomeFormPage'
// import HomeVideoPage from '../screens/ComponentsPages/HomeVideoPage'
// import Product from './Product';

const Product = lazy(()=> import('./Product'));
const Banner = React.lazy(()=> import('../components/Banner'));
const HomepageSectionOne = React.lazy(()=> import('../screens/ComponentsPages/HomepageSection1'));
const HomepageSectionTwo = React.lazy(()=> import('../screens/ComponentsPages/HomepageSection2'));
const HomepageSectionFive = React.lazy(()=> import('../screens/ComponentsPages/HomepageSection5'));
const ScrillingImages = React.lazy(()=> import('../screens/ComponentsPages/ScrillingImages'));
const HomePageGallery = React.lazy(()=> import('../screens/ComponentsPages/HomePageGallery'));
const HomeProductRangePage = React.lazy(()=> import('../screens/ComponentsPages/HomeProductRangePage'));
const HomeFormPage = React.lazy(()=> import('../screens/ComponentsPages/HomeFormPage'));
const HomeVideoPage = React.lazy(()=> import('../screens/ComponentsPages/HomeVideoPage'));

 

function Home() {
  return (
    <div className='relative  '>  

          <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Manufacturer & Dealer.
                               All Types of PVC Pipe Bending Machines.'
                               
                />
                <meta name='keywords' 
                      content='Semi Automatic pvc Pipe Bending Machine, Automatic pvc pipe Bending Machine, PVC Pipe Bending Machine, PVC Pipe Cutter Machine, PVC Pipe Coupler Machine, PVC Pipe Soketing Machine, Bending Machine, Cutter Machine, Electric Pipe Bending Machine, Bend Making Machine '        
                />
                <title>Marudhar Machines</title>
                <link rel="canonical" href="https://marudharmachines.com/" />
          </Helmet>

            <Suspense fallback={<div>Loading...</div>}>
            <Banner/>
        <div className='bg-purpleHeader'>

          <ScrillingImages/>
           <HomepageSectionOne/>
           <HomepageSectionTwo/> 
          <HomeProductRangePage/>
          
          
            <Product/>
         
          <HomeVideoPage/>
          <HomepageSectionFive/> 
          <HomePageGallery/>
          <HomeFormPage/>
          
        </div>
        </Suspense>
    </div>
  )
}

export default Home;