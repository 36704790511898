import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
 
// import AboutPageSection1 from '../screens/ComponentsPages/AboutPageSection1'
// import GalleryPage from '../screens/ComponentsPages/GalleryPage'
// import AboutCallPage from '../screens/ComponentsPages/AboutCallPage'

const AboutPageSection1 = React.lazy(()=> import('../screens/ComponentsPages/AboutPageSection1'));
const GalleryPage = React.lazy(()=> import('../screens/ComponentsPages/GalleryPage'));
const AboutCallPage = React.lazy(()=> import('../screens/ComponentsPages/AboutCallPage'));

 

const Gallery  = () => {
    return (
        <div className='relative'>

        <Helmet>
                <meta charSet="utf-8" />
                <meta name='description'
                      content='Manufacturer & Dealer.
                               All Types of PVC Pipe Bending Machines.'
                               
                />
                <meta name='keywords' 
                      content='Semi Automatic pvc Pipe Bending Machine, Automatic pvc pipe Bending Machine, PVC Pipe Bending Machine, PVC Pipe Cutter Machine, PVC Pipe Coupler Machine, PVC Pipe Soketing Machine, Bending Machine, Cutter Machine, Electric Pipe Bending Machine, Bend Making Machine '        
                />
                <title>Gallery</title>
                <link rel="canonical" href="https://marudharmachines.com/gallery" />
          </Helmet>

            <Suspense fallback={<div>Loading...</div>}>

            <div className='bg-purpleHeader'>
                 
                <AboutPageSection1/>
                <GalleryPage/>
                 
                <AboutCallPage/>
                 
            </div>
        </Suspense>

        </div>

        );
    }
export default Gallery