import React,{useState} from 'react'
import { IoIosMail } from "react-icons/io";
import { GrInstagram } from "react-icons/gr";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { BiSolidPhoneCall } from "react-icons/bi";
import { GrGallery } from "react-icons/gr";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdContactPhone } from "react-icons/md";
import { FaHome } from "react-icons/fa";
// import { Spin as Hamburger } from 'hamburger-react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
// import Homepage from './screens/Homepage/Homepage';
import {NavLink} from "react-router-dom";
import {Link} from "react-router-dom";




const Header = () => {

//  const [isOpen, setOpen] = useState(false) 

 const [nav,setNav] =useState(false)

 const handleNav=()=>{
      setNav(!nav);
 }



  return (
    <>

    <div className=' invisible md:visible w-0  md:w-full h-0 md:h-8 bg-black  px-0 md:px-10 lg:px-20 xl:px-[85px] 2xl:px-[100px]  place-content-center items-center flex justify-between  '>
    
        <div className='invisible md:visible  flex  justify-between '>
            <IoIosMail className='invisible md:visible  text-white  w-0 h-0  md:w-10 md:h-7 ' />
            <h1 className='text-white invisible md:visible '><Link to="mailto:marudharindustries5@gmail.com ">marudharindustries5@gmail.com </Link> </h1>
        </div>

        <div className='invisible md:visible  flex  justify-between '>
            <BiSolidPhoneCall  className='invisible md:visible  text-white  w-0 h-0 md:w-10 md:h-6 ' />
            <h1 className='text-white invisible md:visible '><Link to="tel:+91 8090409020">+91 8090409020 </Link></h1>
        </div>

        <div className='invisible md:visible  flex  justify-between '>
            <Link to="https://www.instagram.com/marudhar.machines"><GrInstagram  className='invisible md:visible  text-white w-0 h-0 md:w-8 md:h-5 '/></Link>
            <Link to="https://www.facebook.com/marudhar.industries" > <FaFacebook   className=' invisible md:visible text-white w-0 h-0 md:w-8 md:h-5' /></Link>
            <Link to="https://twitter.com/Marudharmachine" > <FaTwitter    className=' invisible md:visible text-white  w-0 h-0 md:w-8 md:h-5'  /></Link>
            <Link to="https://www.youtube.com/@marudharindustries" > <FaYoutube    className=' invisible md:visible text-white  w-0 h-0 md:w-8 md:h-5'  /></Link>
        </div>

    </div>



    <div className='bgblurcss drop-shadow-md sticky top-0 bg-opacity-95  border-gray-200 bg-[#E4ECF9] z-[100] border border-black  text-black  flex justify-between items-center h-14 md:h-20 w-full   px-6 md:px-10 lg:px-20 xl:px-[85px] 2xl:px-[90px]'>
    <Link to="/" ><img className="drop-shadow-sm  w-[55px] h-[40px] md:w-[85px] md:h-[65px] " src="images/LOGO1.png" alt="marudhar machines"/></Link>
        
        {/* <h1 className='w-full text-3xl  tracking-[5px] font-mono font-bold  text-[#00df9a]'>RM.</h1> */}
           
        <ul className='hidden md:flex '>
            <Link to="/" ><li className=' p-4 active:font-bold active:text-lg font-normal text-[black] active:text-red-600 hover:text-[red] active:underline underline-offset-8  transition duration-400 ease-out hover:ease-in cursor-pointer'>  <NavLink to="/" >Home</NavLink></li></Link>
            <Link to="/about"><li className='p-4 active:font-bold active:text-lg font-normal text-[black]  hover:text-[red] active:underline underline-offset-8  transition duration-400 ease-out hover:ease-in cursor-pointer'> <NavLink to="/about">About</NavLink></li></Link>
            <Link to="/products"> <li className='p-4 active:font-bold active:text-lg font-normal text-[black]  hover:text-[red] active:underline underline-offset-8  transition duration-400 ease-out hover:ease-in cursor-pointer'><NavLink to="/products">Our Product</NavLink></li> </Link>
            <Link to="/gallery"><li className='p-4 active:font-bold active:text-lg font-normal text-[black]  hover:text-[red] active:underline underline-offset-8  transition duration-400 ease-out hover:ease-in cursor-pointer'><NavLink to="/gallery">Gallery</NavLink></li></Link>
            <Link to="/contactUs"><li className='p-4 active:font-bold active:text-lg font-normal text-[black]  hover:text-[red] active:underline underline-offset-8  transition duration-400 ease-out hover:ease-in cursor-pointer'><NavLink to="/contactUs">Contact Us</NavLink></li></Link>
           
        </ul>
            <Link to="tel:+91 8090409020"> 
            <button className="  w-[122px]  md:w-[130px] lg:w-[150px] flex place-content-center items-center  gap-2 bg-[#AF8DF8]  p-[9px_15px] shadow-[-6px_-6px_10px_#FFFFFF,6px_6px_10px_rgba(0, 0, 0, 0.2),inset_-2px_-2px_10px_rgba(0, 0, 0, 0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)] rounded-full hover:bg-[#BD8DF9] hover:drop-shadow-lg active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300         
                      hover:scale-105 duration-200  hover:border-gray-800 hover:from-black hover:to-gray-900    shadow-[-4px_-4px_9px_rgba(255,255,255,0.26),6px_6px_16px_rgba(0,0,0,0.3),inset_-2px_-2px_10px_rgba(0,0,0,0.25),inset_3px_3px_10px_rgba(255,255,255,0.3)]">                                   
                      <img className="  w-[16px] h-[16px] md:w-[20px] md:h-[20px] " src="images/phone.svg" alt=""/>
                      
                     <span className="text-[#ffff] tracking-[0.05em] font-semibold text-[14px] md:text-[16px] lg:text-[18px]">
                      Call Now
                      </span>
            </button>
            </Link>
    </div>

        

          <div className="md:hidden px-4 bg-white shadow-lg rounded-[0px] bottom-[0px]  fixed z-[100] min-w-full   flex  place-content-center ">

            

            <div class="flex h-[58px] place-content-center ">

                <div className="flex group">
                    <Link to="/"  className="flex place-content-center items-end justify-center text-center mx-auto px-2 pt-0 w-full active:text-red text-black  ">
                        <span className="block px-1 pt-0 pb-0 grid place-content-center">
                            <FaHome className="  place-self-center  text-2xl pt-0 mb-0 block" />
                            {/* <img src="/images/home.gif" alt="star" className=" place-self-center w-8 text-2xl pt-0 mb-0 block"/> */}
                            <span className="block text-xs active:text-[red] pb-1"><NavLink to="/" > Home </NavLink></span>
                            <span className="block w-8 mx-auto h-[4px] group-hover:bg-[red] group-active:bg-[red] rounded-full"></span>
                        </span>
                    </Link>
                </div>

                 

                <div class="flex group">
                    <Link to="/about" class="flex place-content-center items-end justify-center text-center mx-auto px-2 pt-0 w-full text-black active:text-red">
                        <span class="block px-1 pt-0 pb-0  grid place-content-center ">
                            <HiOutlineUserGroup className="  place-self-center  text-2xl pt-0 mb-0 block" />
                            {/* <img src="/images/gallery2.gif" alt="star" className=" place-self-center w-8 text-2xl pt-0 mb-0 block"/> */}
                            <span class="block text-xs pb-1"><NavLink to="/about">About</NavLink></span>
                            <span class="block w-8 mx-auto h-[4px] group-hover:bg-[red] rounded-full"></span>
                        </span>
                    </Link>
                </div>

                <div class="flex group">
                    <Link to="/gallery" class="flex place-content-center items-end justify-center text-center mx-auto px-2 pt-0 w-full text-black active:text-red">
                        <span class="block px-1 pt-0 pb-0  grid place-content-center ">
                            <GrGallery className="  place-self-center  text-2xl pt-0 mb-0 block" />
                            {/* <img src="/images/gallery2.gif" alt="star" className=" place-self-center w-8 text-2xl pt-0 mb-0 block"/> */}
                            <span class="block text-xs pb-1"><NavLink to="/gallery">Gallery</NavLink></span>
                            <span class="block w-8 mx-auto h-[4px] group-hover:bg-[red] rounded-full"></span>
                        </span>
                    </Link>
                </div>

                <div class="flex group">
                    <Link to="/contactUs" class="flex place-content-center items-end justify-center text-center mx-auto px-2 pt-0 w-full text-black active:text-red">
                        <span class="block px-1 pt-0 pb-0 grid place-content-center">
                        <MdContactPhone className="  place-self-center  text-2xl pt-0 mb-0 block" />
                        {/* <img src="/images/about.gif" alt="star" className=" place-self-center w-8 text-2xl pt-0 mb-0 block"/> */}
                            <span class="block text-xs pb-1"><NavLink to="/contactUs">Contact</NavLink></span>
                            <span class="block w-8 mx-auto h-[4px] group-hover:bg-[red] rounded-full"></span>
                        </span>
                    </Link>
                </div>


                <div onClick={handleNav} className='block md:hidden flex '>
                        <div className='flex place-content-center  px-2 justify-center'>
                       { !nav  ?  <AiOutlineMenu size={30} className='place-self-center '/>: <AiOutlineClose size={30} className='place-self-center '/>}
                       {/* <Hamburger direction="right" className='place-self-center ' toggled={isOpen} toggle={setOpen} />  */}
                        </div>
                    <div className= {!nav ? 'fixed top-0 h-full w-[60%] left-[-100%] ease-in-out duration-700 '  : 'fixed left-0 top-0 w-[60%] h-full border-r border-r-[#06555b] backdrop-blur-xl  transition duration-300 ease-in-out ease-in '}>
                        
                        <ul className='pt-5  bg-white opacity-80 ' >
                                <Link to="/SemiAutomaticPVCPipeBendingMachine" ><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Semi Automatic PVC Pipe Bending Machine</li></Link>
                                <Link to="/AutomaticPvcPipeBendingMachine"><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Automatic PVC Pipe Bending Machine</li></Link>
                                <Link to="/FullyAutomaticSilentPvcPipeCuttingMachine"><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Fully Automatic Silent PVC Pipe Cutting Machine</li></Link> 
                                <Link to="/FullyAutomaticPvcPipeBendingMachine" ><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Fully Automatic PVC Pipe Bending Machine</li> </Link>
                                <Link to="/AutomaticPvcPipeTableCutterMachine"><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Automatic pvc pipe Table cutter machine</li> </Link> 
                                <Link to="/ShortPvcPipeCouplerMachine"> <li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Short PVC Pipe Coupler Machine</li> </Link>
                                <Link to="/PvcPipeBendingMachine"><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Pvc Pipe Bending Machine</li></Link> 
                                <Link to="/PvcPipeManualCutterMachine"> <li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>PVC Pipe Manual Cutter Machine</li></Link> 
                                <Link to="/SemiAutomaticElectricalConduitPvcPipeBendingMachine" ><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Semi Automatic Electrical Conduit PVC Pipe Bending Machine</li></Link>
                                <Link to="/SemiAutomaticShortPvcPipeSoketMakingMachine"><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Semi Automatic short PVC pipe soket Making Machine</li> </Link>
                                <Link to="/ManualPvcPipeBendingMachine"><li className='py-[2px] border-separate border px-3 font-medium text-[16px] bg-black my-[2px] text-[white] transition duration-300 ease-out hover:ease-in cursor-pointer'>Manual Pvc Pipe Bending Machine</li>  </Link>
                                
                                <li className='flex py-[18px] border boder-8 border-black px-1 font-medium text-[13.5px] bg-white my-[2px] text-[white] w-full place-content-center'>
                                <Link to="https://www.instagram.com/marudhar.machines"> <GrInstagram  className=' mx-2   text-black  w-8 h-5'/></Link>
                                <Link to="https://www.facebook.com/marudhar.industries" > <FaFacebook   className=' mx-2 text-black w-8 h-5' /></Link>
                                <Link to="https://twitter.com/Marudharmachine" > <FaTwitter    className=' mx-2  text-black w-8 h-5' /></Link>
                                <Link to="https://www.youtube.com/@marudharindustries" > <FaYoutube    className=' mx-2  text-black w-8 h-5' /></Link>
                                </li>
                        </ul> 
        
                    </div>
                </div>


            </div>
        </div>
        

        
        </>
  )
}

export default Header