import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import ContactUs from './screens/ContactUs';
import Gallery from './screens/Gallery';
import Product from './screens/Product';
import Error from './screens/Error';
import ChatButton from './screens/ChatButton';
import SemiAutomaticPVCPipeBendingMachine from './screens/Products/SemiAutomaticPVCPipeBendingMachine';
import AutomaticPvcPipeBendingMachine from './screens/Products/AutomaticPvcPipeBendingMachine';
import AutomaticPvcPipeTableCutterMachine from './screens/Products/AutomaticPvcPipeTableCutterMachine';
import FullyAutomaticPvcPipeBendingMachine from './screens/Products/FullyAutomaticPvcPipeBendingMachine';
import FullyAutomaticSilentPvcPipeCuttingMachine from './screens/Products/FullyAutomaticSilentPvcPipeCuttingMachine';
import ManualPvcPipeBendingMachine from './screens/Products/ManualPvcPipeBendingMachine';
import PvcPipeBendingMachine from './screens/Products/PvcPipeBendingMachine';
import PvcPipeManualCutterMachine from './screens/Products/PvcPipeManualCutterMachine';
import SemiAutomaticElectricalConduitPvcPipeBendingMachine from './screens/Products/SemiAutomaticElectricalConduitPvcPipeBendingMachine';
import SemiAutomaticShortPvcPipeSoketMakingMachine from './screens/Products/SemiAutomaticShortPvcPipeSoketMakingMachine';
import ShortPvcPipeCouplerMachine from './screens/Products/ShortPvcPipeCouplerMachine';
import ScrollToTop from "./components/ScrollToTop";
import Header from './components/Header';
import Footer from './components/Footer';


function App() {
  return (
     
    <BrowserRouter>
      <ScrollToTop />
       <Header/> 
       <ChatButton/>
        <Routes>

            <Route exact path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contactUs" element={<ContactUs/>}/>
                <Route path="/gallery" element={<Gallery/>}/>
                
                <Route path="/products" element={<Product/>}/>
                    <Route path="/SemiAutomaticPVCPipeBendingMachine" element={<SemiAutomaticPVCPipeBendingMachine/>}/>
                    <Route path="/AutomaticPvcPipeBendingMachine" element={<AutomaticPvcPipeBendingMachine/>}/>
                    <Route path="/AutomaticPvcPipeTableCutterMachine" element={<AutomaticPvcPipeTableCutterMachine/>}/>
                    <Route path="/FullyAutomaticPvcPipeBendingMachine" element={<FullyAutomaticPvcPipeBendingMachine/>}/>
                    <Route path="/FullyAutomaticSilentPvcPipeCuttingMachine" element={<FullyAutomaticSilentPvcPipeCuttingMachine/>}/>
                    <Route path="/ManualPvcPipeBendingMachine" element={<ManualPvcPipeBendingMachine/>}/>
                    <Route path="/PvcPipeBendingMachine" element={<PvcPipeBendingMachine/>}/>
                    <Route path="/PvcPipeManualCutterMachine" element={<PvcPipeManualCutterMachine/>}/>
                    <Route path="/SemiAutomaticElectricalConduitPvcPipeBendingMachine" element={<SemiAutomaticElectricalConduitPvcPipeBendingMachine/>}/>
                    <Route path="/SemiAutomaticShortPvcPipeSoketMakingMachine" element={<SemiAutomaticShortPvcPipeSoketMakingMachine/>}/>
                    <Route path="/ShortPvcPipeCouplerMachine" element={<ShortPvcPipeCouplerMachine/>}/>
                {/* </Route> */}
                <Route path="*" element={<Error/>}/>
            {/* </Route> */}
        </Routes>
      <Footer/>  
    </BrowserRouter>
   
  );
}

export default App;
